.meta {
  font-weight: bold;
  text-transform: uppercase;
}

.home .strip-item:nth-child(even) {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
}

.home .strip-item:nth-child(odd) {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
  transform: translateY(-40%);
}

@media (max-width: 50rem) {
  .home .strip-item {
    transform: none !important;
  }
}

.pull-right {
  float: right;
}

.home-slide-link {
  display: block;
  position: relative;
}

.home-slide-title {
  position: absolute;
  right: -1.2rem;
  bottom: 0;
  font-size: 1.5rem;
  font-family: 'GT-Spectra', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase !important;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  text-transform: uppercase;
  transform: rotate(90deg);
  transform-origin: right;
}

.home {
  position: absolute;
  right: 0;
  top: -20vmin;
}

.home .strip {
  height: 80vmin;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
}

.home .content {
  max-height: none;
}

.home .image-element {
  height: auto;
  width: auto;
  max-height: none;
  max-width: 100% !important;
}

@media (max-height: 50rem) and (min-width: 50rem) {
  .home {
    top: 0vmin;
  }
}

@media (max-width: 50rem) {
  .home {
    position: static;
    top: auto;
    right: auto;
  }

  .home .strip {
    height: auto;
  }

  .home .image-element {
    max-width: 100%;
    max-height: auto;
    height: auto;
    width: auto;
  }

  .home-slide-title {
    display: block;
    transform: none;
    left: 0;
    bottom: -1.11111rem;
    font-size: 1.11111rem;
    line-height: 1;
  }

  .home.pages-item {
    padding-top: 5.5rem;
  }

  .home .strip-item:nth-child(odd) {
    padding-left: 6rem;
  }

  .home .strip-item:nth-child(even) {
    padding-right: 6rem;
  }
}

.strip {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 47px;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  grid-auto-columns: 1fr;
  overflow: none;
  width: 100%;
  height: 100%;
  min-height: 600px;
}

.strip-item {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
  position: relative;
}

.strip-item--start {
  -ms-flex-item-align: start;
      -ms-grid-row-align: start;
      align-self: start;
}

.strip-item--end {
  -ms-flex-item-align: end;
      -ms-grid-row-align: end;
      align-self: end;
}

.strip-item--center {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.strip-item--stretch {
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
}

.strip-item--merge {
  margin-right: -36px;
}

@media (max-width: 50rem) {
  .strip {
    display: block;
    min-height: auto;
  }

  .strip-item {
    margin-bottom: 1rem;
  }

  .strip-item--merge {
    margin: 0 0 0.25rem 0;
  }
}

.pages {
  position: relative;
  min-width: calc(100vw - 475px - 100px);
}

.pages-item.fade-exit,
.pages-item.fade-enter {
  left: 0;
  top: 0;
  position: absolute;
  transition: transform 600ms, opacity 600ms;
  transition-delay: 0ms, 0ms;
}

.pages-item.fade-exit.home,
.pages-item.fade-enter.home {
  top: -20vmin;
}

.pages-item.fade-exit {
  opacity: 1;
  overflow-x: hidden;
  /*max-width: 100vw;*/
}

.pages-item.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
}

.pages-item.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.pages-item.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
}

@media (max-width: 50rem) {
  .pages {
    position: static;
    min-width: auto;
    min-height: 100vh;
  }

  .pages-item {
    padding-top: 17rem;
    box-sizing: border-box;
    min-height: 100vh;
    display: -ms-grid;
    display: grid;
    -ms-flex-align: end;
        align-items: end;
  }
}

.prev-project-button,
.next-project-button {
  display: block;
  height: 5rem;
  line-height: 5rem;
  border: none;
  background: none;
  cursor: pointer;
  text-align: right;
  padding-right: 2.5rem;
  max-width: 22ch;
  min-width: 22ch;
}

.site-black .prev-project-button .link-arrow .changeable,
.site-black
  .next-project-button .link-arrow .changeable {
  stroke: #fff;
}

.prev-project-button .link-arrow,
.next-project-button .link-arrow {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  height: 65px;
  width: 80px;
}

.prev-project-button .link-arrow .handle,
.next-project-button .link-arrow .handle {
  opacity: 0;
  transform-origin: 100% 0;
  transition: all 250ms ease-in-out;
}

.prev-project-button .link-text,
.next-project-button .link-text {
  font-family: 'GT-Spectra', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase !important;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 1.11111rem;
  display: inline-block;
  transition: all 250ms ease-in-out;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 0rem;
  line-height: 1.5rem;
  vertical-align: middle;
  position: relative;
  top: -4px;
}

.prev-project-button:hover .link-arrow .handle,
.next-project-button:hover .link-arrow .handle {
  opacity: 0;
}

.prev-project-button:hover .link-text,
.next-project-button:hover .link-text {
  background: #000;
  transform: translateX(2.1rem) translateY(0.15rem) scale(1, 0.2);
  width: 5ch;
  line-height: 1.11rem;
  letter-spacing: 50%;
}

.site-black .prev-project-button:hover .link-text,
.site-black
    .next-project-button:hover .link-text {
  background: #fff;
  color: #fff;
}

.prev-project-button-mobile,
.next-project-button-mobile {
  display: none !important;
}

@media (max-width: 50rem) {
  .prev-project-button,
  .next-project-button {
    text-align: center;
    line-height: 2rem;
    float: none;
    margin: 0rem 0rem;
    margin-top: -1rem;
    margin-bottom: 2.5rem;
    padding: 0;
    display: block;
    width: 100%;
    height: 2rem;
  }

  .prev-project-button-desktop .link-arrow,
  .next-project-button-desktop .link-arrow {
    display: none;
  }

  .prev-project-button-mobile,
  .next-project-button-mobile {
    display: block !important;
  }

  .prev-project-button-desktop,
  .next-project-button-desktop {
    display: none !important;
  }
}

@media (max-width: 50rem) {
  .prev-project-button-mobile {
    display: block;
    float: left;
    width: 4rem;
    min-width: auto;
    text-align: left;
    transform: scale(-0.5, 0.5) translateX(50%);
  }

  .next-project-button-mobile {
    display: block;
    float: right;
    min-width: auto;
    width: 4rem;
    text-align: right;
    transform: scale(0.5, 0.5) translateX(50%);
  }
}

.large-text {
  column-count: 3;
  column-gap: 50px;
}

@media (max-width: 50rem) {
  .large-text {
    column-count: 1;
  }
}

.content {
  font-family: 'Courier New', monospace;
  font-size: 1rem;
  min-width: 320px;
  max-height: 600px;
}

.content-title {
  margin-bottom: 2.22rem;
  font-size: 1.11111rem;
}

.content-text {
  max-width: 320px;
  margin-bottom: 2.22rem;
}

.content-image {
  max-width: auto;
}

.content h1 {
  margin-top: 0;
  margin-bottom: 2.22rem;
  font-size: 1.11111rem;
  text-transform: normal;
}

.content h2 {
  font-family: 'Courier New', monospace;
  margin-top: 0;
  margin-bottom: 1.11rem;
  font-size: 1rem;
  text-decoration: underline;
  font-weight: normal;
}

.content p {
  margin-top: 0;
  margin-bottom: 1.11rem;
}

.content .meta {
  font-size: 0.83333rem;
  font-weight: normal;
  font-family: 'GT-Spectra', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase !important;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

.content a {
  text-decoration: underline;
  display: inline-block;
  color: inherit;
  position: relative;
  transition: all 150ms ease-out;
}

.content a.link:hover,
.content-text a:hover {
  color: #fff;
}

.content a.link:before,
.content-text a:before {
  color: #fff;
  z-index: -1;
  content: attr(data-text);
  position: absolute;
  height: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 150ms ease-out;
}

.content a.link:hover:before,
.content-text a:hover:before {
  height: 100%;
  background: #000;
}

.overlay-content .content a.link:hover,
.overlay-content .content-text a:hover {
  color: #000;
}

.overlay-content .content a.link:hover:before,
.overlay-content .content-text a:hover:before {
  background: #FFF;
}

@media (max-width: 50rem) {
  .content {
    max-width: auto;
    min-width: auto;
    margin-bottom: 3rem;
  }
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  min-width: 70vw;
  width: 100%;
  max-width: 900px;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'GT-Spectra';
  font-weight: bold;
  font-style: italic;
  src: url(/src/fonts/GT-Sectra-Display-Bold-Italic.2fa08.woff2) format("woff2"), url(/src/fonts/GT-Sectra-Display-Bold-Italic.5eadd.woff) format("woff");
}

body,
html {
  padding: 0;
  margin: 0;
  font-size: 18px;
  line-height: 1.11;
  box-sizing: border-box;
  overflow: none;
  -webkit-overflow-scrolling: touch;
  text-transform: lowercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
}

@media (max-width: 50rem) {
  body,
  html {
    overflow: none;
  }
}

body {
  background: #ffffff;
  font-family: 'Courier New', monospace;
}

h1,
h2,
h3 {
  font-family: 'GT-Spectra', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase !important;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  letter-spacing: 0.025em;
  -webkit-font-variant-ligatures: common-ligatures;
          font-variant-ligatures: common-ligatures;
}

