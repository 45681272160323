.overlay {
  display: block;
  position: fixed;
  opacity: 1;
  background: #000;
  color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: visible;
  transition: visibility 0s linear, transform 250ms ease-in-out;
  transform: translateY(0%);
  z-index: 300;
  overflow: hidden;
}

.overlay-close {
  padding: 0;
  border: none;
  background: none;
  position: absolute;
  top: 50px;
  left: 76px;
  cursor: pointer;
}

.overlay-close:active,
.overlay-close:focus {
  outline: none;
}

.overlay-close .roto {
  transform-origin: 50% 50%;
  transition: transform 150ms ease-in-out;
}

.overlay-close:hover .roto {
  transform: rotate(-10deg);
}

.overlay-close:hover .roto:nth-child(even) {
  transform: rotate(10deg);
}

.overlay-close-text {
  display: none;
}

.overlay-hidden {
  visibility: hidden;
  transform: translateY(-100%);
  transition: visibility 0s linear 250ms, transform 250ms ease-in-out;
}

.overlay-content {
  padding: 50px 76px;
  display: -ms-grid;
  display: grid;
  height: 100%;
  box-sizing: border-box;
  -ms-grid-column-align: center;
      justify-items: center;
  -ms-flex-align: center;
      align-items: center;
  overflow-x: scroll;
}

.overlay-content .content {
  max-height: none;
}

@media (max-width: 50rem) {
  .overlay-close {
    margin: 0;
    position: absolute;
    z-index: 500;
    display: block;
    top: auto;
    width: auto;
    height: auto;
    left: auto;
    right: -1.75rem;
    bottom: 3.25rem;
    transform-origin: 0px 0px;
    transform: rotate(90deg);
    line-height: 1;
  }

  .overlay-close-image {
    display: none;
  }

  .overlay-close-text {
    font-family: 'GT-Spectra', sans-serif;
    font-weight: normal;
    font-style: italic;
    text-transform: lowercase !important;
    letter-spacing: 0.05em;
    -webkit-text-size-adjust: 100%;
    /* Font varient */
    font-variant-ligatures: none;
    -webkit-font-variant-ligatures: none;
    /* Smoothing */
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    display: block;
    color: white;
    width: auto;
    height: auto;
    font-size: 1.11111rem;
    text-transform: uppercase;
  }

  .overlay-content {
    padding-right: 2.4rem;
    padding-left: 1.4rem;
    word-break: break-all;
  }
}

