.site {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: scroll;
  transition: all 150ms ease-in-out;
  -webkit-overflow-scrolling: touch;
}

.site-black {
  background: black;
  color: white;
}

@media (max-width: 50rem) {
  .site {
    position: static;
    max-height: 100vh;
  }
}

.site-header {
  position: fixed;
  left: 75px;
  width: 350px;
  height: 400px;
  top: 50px;
  z-index: 200;
  transform: translateY(-150%);
  transition: transform 250ms ease-in-out, top 250ms ease-in-out, left 250ms ease-in-out;
}

.site-is-loaded .site-header {
  transform: translateY(0%);
}

.site-header-title {
  float: left;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  transform-origin: 0 0 0;
  transform: translateX(100%) rotateZ(90deg);
  font-size: 2.44444rem;
  line-height: 2.44444rem;
  letter-spacing: 0.025em;
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  color: inherit;
  text-decoration: none;
  transition: transform 150ms ease-in-out;
}

.site-header-title a {
  color: inherit;
  text-decoration: none;
}

.site-header-title .word {
  position: relative;
  color: black;
  transition: color 250ms linear;
}

.site-black .site-header-title .word {
  color: white;
}

.site-header-title .word:after,
.site-header-title .word:before {
  display: block;
  position: absolute;
  top: 15%;
  left: 0;
  height: 70%;
  width: 0%;
  content: '';
  transition: width 250ms ease-in-out, visibility 0s;
  opacity: 1;
  background: black;
}

.site-black .site-header-title .word:after,
.site-black .site-header-title .word:before {
  background: white;
}

.is-scrolled .site-header:not(:hover) {
  top: 5px;
}

.is-scrolled .site-header:not(:hover) .site-header-title {
  transform: scale(0.4) translateX(100%) rotateZ(90deg);
}

.is-scrolled .site-header:not(:hover) .site-header-title .word {
  color: rgba(0, 0, 0, 0);
}

.site-black .is-scrolled .site-header:not(:hover) .site-header-title .word {
  color: rgba(255, 255, 255, 0);
}

.is-scrolled .site-header:not(:hover) .site-header-title .word:after {
  width: 101%;
  transition: visibility 0s, width 250ms ease-in-out;
  transition-delay: 250ms, 0;
}

@media (min-width: 130rem) {
  .site-header-title {
    font-size: 3rem;
    line-height: 3rem;
  }
}

@media (max-width: 50rem) {
  .site-header {
    position: fixed;
    left: 0rem;
    top: 0rem;
    height: 0;
    width: 100vw;
  }

  .site-header-title {
    float: none;
    position: absolute;
    top: 1.3rem;
    left: 1.3rem;
    font-size: 1.44rem;
    line-height: 1.44rem;
    transform-origin: 0 0 0;
    transform: rotateZ(90deg) translateY(-100%);
  }

  .is-scrolled .site-header:not(:hover) {
    top: 0;
  }

  .is-scrolled .site-header:not(:hover) .site-header-title {
    transform: rotateZ(90deg) translateY(-50%) scale(0.5);
  }
}

@media (min-width: 50rem) and (max-width: 88rem), (max-height: 50rem) {
  .site-header-title {
    left: 1rem;
    transform: rotateZ(90deg) translateY(-90%) translateX(-0.5rem);
  }

  .is-scrolled .site-header:not(:hover) .site-header-title {
    transform: rotateZ(90deg) translateY(-50%) scale(0.5);
  }
}

@media (min-width: 50rem) and (max-width: 88rem), (max-height: 50rem) {
  .site-header-title {
    left: 2rem;
    transform: rotateZ(90deg) translateY(-90%) translateX(-0.5rem);
  }

  .is-scrolled .site-header:not(:hover) .site-header-title {
    transform: rotateZ(90deg) translateY(-50%) scale(0.5);
  }
}

.site-nav {
  position: fixed;
  left: 75px;
  bottom: -300px;
  z-index: 200;
  min-height: 200px;
  min-width: 250px;
  padding-left: 50px;
  padding-bottom: 50px;
  transition: bottom 250ms ease-in-out;
}

.site-is-loaded .site-nav {
  bottom: 50px;
}

.site-nav-title,
.site-nav-close {
  position: absolute;
  text-transform: uppercase;
  transform-origin: 0 0 0;
  transform: rotateZ(90deg) translateX(-50px);
  bottom: 0;
  margin: 0;
  right: auto;
}

.site-nav-close {
  display: none;
}

.site-nav-title {
  position: absolute;
  text-transform: uppercase;
  transform-origin: 0 0 0;
  transform: rotateZ(90deg) translateX(-50px);
  bottom: 0;
  margin: 0;
  margin-bottom: 50px;
  left: 0;
  transition: transform 150ms ease-in-out, transform-origin 150ms ease-in-out, background 150ms ease-in-out;
}

.is-scrolled .site-nav:not(:hover) .site-nav-title {
  background: black;
  transform-origin: 0 75% 0;
  transform: scale(0.4) rotateZ(90deg) translateX(-50px);
}

.site-black.is-scrolled .site-nav:not(:hover) .site-nav-title {
  background: white;
}

.site-nav-column {
  justify-self: start;
  -ms-flex-align: stretch;
      align-items: stretch;
  transition: opacity 350ms ease-in;
  opacity: 1;
  position: relative;
}

.site-nav-column-empty {
  opacity: 0;
}

.site-nav-column .fade-exit,
.site-nav-column .fade-enter {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  transition: opacity 250ms ease-out;
  max-height: 105px;
}

.site-nav-column .fade-enter {
  opacity: 0;
}

.site-nav-column .fade-exit-active {
  opacity: 0;
}

.site-nav-column .fade-enter-active {
  opacity: 1;
}

@media screen and (min-width: 50rem) {
  .site-nav-column .link-group-container {
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 2.77778rem;
  }
}

.site-nav-column-sub {
  column-gap: 1.33333rem;
  height: 105px;
  font-style: italic;
}

.site-nav-link-container {
  position: absolute;
  bottom: 0;
  left: 1rem;
  transform-origin: 0 60% 0;
  transition: transform 150ms ease-in-out, background 150ms ease-in-out;
  min-width: calc(90vw - 10rem);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  grid-auto-flow: column;
  grid-gap: 2.77778rem;
  -ms-flex-align: end;
      align-items: end;
}

.is-scrolled .site-nav:not(:hover) {
  bottom: 10px;
}

.is-scrolled .site-nav:not(:hover) .site-nav-link-container {
  transform: scale(0.4, 0.3);
}

.site-nav-link {
  display: inline-block;
  float: left;
  clear: left;
  color: inherit;
  text-decoration: none;
  margin-top: 8px;
  line-height: 1;
  font-family: inherit;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
}

.site-nav-link:before {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0%;
  bottom: 0;
  background: #000000;
  transition: width 250ms ease-out;
}

.site-nav-link:after {
  position: absolute;
  bottom: 1px;
  display: block;
  content: '';
  height: 1px;
  left: 0;
  width: 100%;
  background: #000;
  content: '';
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.site-black .site-nav-link:after {
  background: #fff;
}

.site-nav-link.active:after,
.site-nav-link:hover:after {
  opacity: 1;
}

.is-scrolled .site-nav:not(:hover) .site-nav-link:before {
  width: 100%;
}

.site-black .site-nav-link:before {
  background: #fff;
}

.site-nav-toggle {
  position: fixed;
  bottom: -50px;
  left: 131px;
  background: none;
  border: none;
  height: 24px;
  width: 50px;
  transition: bottom 150ms ease-in-out;
}

.is-scrolled .site-nav-toggle {
  bottom: 50px;
}

@media (max-width: 50rem), (max-height: 50rem) {
  .site-nav {
    bottom: 0;
    right: -100vw;
    left: auto;
    width: 100vw;
    height: 100vh;
    max-height: none;
    max-width: none;
    padding: 0;
    transition: right 250ms ease-out;
    background: black;
  }

  .site-nav-link {
    font-weight: bold;
    margin: 1.2rem 2rem;
  }

  .site-nav-link:before {
    display: none;
  }

  .site-nav-sublink {
    font-weight: normal;
    font-style: italic;
  }

  .site-nav-sublinks {
    margin-top: -0.6rem;
    margin-bottom: -0.6rem;
    overflow: hidden;
    max-height: 0px;
    transition: max-height 350ms ease-in-out;
    transition: max-height 350ms cubic-bezier(0.48, 0.15, 0.85, 0.44);
  }

  .site-nav-sublinks.selected {
    max-height: 380px;
  }

  .site-nav-sublink:first-child {
    margin-top: 1.2rem;
  }

  .site-nav-sublink:last-child {
    margin-bottom: 1.2rem;
  }

  .site-black .site-nav {
    background: white;
    color: black;
  }

  .is-scrolled .site-nav:not(:hover) {
    bottom: 0;
  }

  .site-is-loaded .site-nav {
    bottom: 0;
  }

  .site-nav-column {
    width: 100%;
  }

  .site-nav-column-sub {
    column-count: 1;
  }

  .site-nav-title {
    background: none;
    cursor: pointer;
    left: -1rem;
    bottom: -2rem;
    font-size: 1.11111rem;
  }

  .site-black .site-nav-title {
    color: white;
  }

  .site-nav-link {
    color: white;
    float: none;
    display: block;
    text-align: center;
  }

  .site-nav.open {
    right: 0vw;
  }

  .site-nav-link-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    bottom: auto;
    display: -ms-grid;
    display: grid;
    text-align: center;
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }

  .site-nav-column {
    justify-self: center;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }

  .site-nav-close {
    color: white;
    display: block;
    font-size: 1.11111rem;
    right: 1rem;
    bottom: 0.6rem;
    transform: translateX(100%) rotateZ(90deg) translateX(-100%);
    cursor: pointer;
  }

  .site-black .site-nav-close {
    color: black;
  }

  .is-scrolled .site-nav:not(:hover) .site-nav-link-container {
    background: none;
    transform: none;
  }

  .is-scrolled .site-nav:not(:hover) .site-nav-title,
  .site-black.is-scrolled .site-nav:not(:hover) .site-nav-title {
    background: none;
    transform-origin: 0 0 0;
    transform: rotateZ(90deg) translateX(-50px);
  }

  .site-black .site-nav-link {
    color: black;
  }
}

.site-main {
  height: 570px;
  margin-left: 475px;
  padding-right: 100px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  -ms-flex-align: stretch;
      align-items: stretch;
  -ms-flex-pack: stretch;
      justify-content: stretch;
  box-sizing: border-box;
}

@media (max-height: 50rem) {
  .site-main {
    top: 45%;
  }
}

@media (max-width: 50rem) {
  .site-main {
    top: 0;
    left: 0;
    height: auto;
    display: block;
    position: static;
    margin: 0;
    padding: 0;
    padding-left: 1.425rem;
    padding-right: 3rem;
    transform: none;
    min-height: 100vh;
  }
}

@media (min-width: 50rem) and (max-width: 88rem) {
  .site-main {
    margin-left: 12rem;
    padding-right: 47px;
    min-width: calc(100% - 15rem);
  }
}

.site-footer {
  position: fixed;
  z-index: 200;
  right: 75px;
  bottom: 50px;
  text-align: right;
}

.site-footer-nav {
  position: fixed;
  z-index: 200;
  bottom: -300px;
  right: 87px;
  min-width: 50vw;
  min-height: 1rem;
  text-align: right;
  transform-origin: 100% 100% 0;
  transition: bottom 250ms ease-in-out, transform 150ms ease-in-out;
}

.site-is-loaded .site-footer-nav {
  bottom: 49px;
}

.site-footer-nav-link {
  font-family: 'Courier New', monospace;
  text-decoration: none;
  color: inherit;
  margin-left: 1rem;
  line-height: 1;
  display: inline-block;
  background: none;
  transition: background 150ms ease-in-out;
  position: relative;
}

.site-footer-nav-link.active {
  text-decoration: underline;
}

.site-footer-nav-link:before {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0%;
  bottom: 0;
  background: #000000;
  transition: width 250ms ease-out;
}

.site-footer-nav-link:after {
  position: absolute;
  bottom: 1px;
  display: block;
  content: '';
  height: 1px;
  left: 0;
  width: 100%;
  background: #000;
  content: '';
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.site-black .site-footer-nav-link:after {
  background: #fff;
}

.site-footer-nav-link.active:after,
.site-footer-nav-link:hover:after {
  opacity: 1;
}

.is-scrolled .site-footer:not(:hover) .site-footer-nav {
  transform: scale(0.6, 0.3);
}

.is-scrolled .site-footer:not(:hover) .site-footer-nav-link:before {
  width: 100%;
}

.site-black .site-footer-nav-link:before {
  background: white;
}

