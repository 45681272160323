.image {
  background: #fff;
  position: relative;
}

.image-is-clickable {
  cursor: pointer;
}

.image-text {
  position: absolute;
  display: inline-block;
  padding: 1rem;
  box-sizing: border-box;
  top: 0rem;
  left: 0rem;
  height: 100%;
  width: 100%;
  color: black;
  background: rgba(255, 255, 255, 0.6);
  font-family: 'GT-Spectra', sans-serif;
  font-weight: normal;
  font-style: italic;
  text-transform: lowercase !important;
  letter-spacing: 0.05em;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  font-size: 0.83333rem;
  font-weight: normal;
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.image-text-value {
  position: absolute;
  bottom: 1.1rem;
  left: 1rem;
}

.strip-item--merge-last .image-text-value {
  display: none;
}

.site-black .image-text {
  color: white;
  background: rgba(0, 0, 0, 0.6);
}

.site-black .image {
  background: black;
}

.strip-item:hover .image-text {
  opacity: 1;
}

.strip-item--merge:hover + .strip-item .image-text,
.strip-item--active .image-text {
  opacity: 1;
}

.image-loader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0%;
  background: black;
  opacity: 1;
  display: block;
}

.image-is-loading .image-loader {
  height: 100%;
  transition: height 150ms ease-in-out, opacity 150ms linear;
  transition-delay: 0ms, 150ms;
}

.image-is-loaded .image-loader {
  height: 100%;
  opacity: 0;
  animation: image-loaded 150ms infinite;
  transition: height 300ms ease-in-out, opacity 150ms linear;
  transition-delay: 0ms, 300ms;
}

.image-element {
  width: auto;
  height: 600px;
  visibility: hidden;
  display: block;
}

.image-is-loaded .image-element {
  visibility: visible;
}

@media (max-width: 50rem) {
  .image-element {
    width: auto;
    height: auto;
    max-width: 100%;
    display: block;
  }

  .strip-item--merge-last .image-element {
    background: black;
  }

  .image-text {
    position: static;
    opacity: 1;
    padding: 0;
  }

  .image-text-value {
    position: static;
    line-height: 1.25rem;
  }

  .strip-item--merge-first .image-text-value {
    display: none;
  }

  .strip-item--merge-last .image-text-value {
    display: block;
  }

  .strip-item--merge-first .image-text {
    display: none;
  }
}

